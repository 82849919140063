import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/main.vue'
import User from '@/views/user.vue'
import AfterSales from '@/views/afterSales.vue'
import Order from '@/views/order.vue'
import addressList from '@/views/addressList.vue'
import wuliu from '@/views/wuliu.vue'
import wlXq from '@/views/wlXq.vue'
import addAddress from '@/views/addAddress.vue'
import my from '@/views/my.vue'
import changePwd from '@/views/changePwd.vue'
import register from '@/views/register.vue'
import phoneLogin from '@/views/phoneLogin.vue'
import pwdLogin from '@/views/pwdLogin.vue'
import qrcode from '@/views/qrcode.vue'
import agreement from '@/views/agreement.vue'
import orderDetails from '@/views/orderDetails.vue'
import forgetPwd from '@/views/forgetPwd.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Main',
    component: Main
  },
  {
    path: '/Main',
    name: 'Main',
    component: Main,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/User',
    name: 'User',
    component: User,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/orderDetails/:id',
    name: 'orderDetails',
    component: orderDetails,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/AfterSales',
    name: 'AfterSales',
    component: AfterSales,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/Order/:active',
    name: 'Order',
    component: Order,
    meta: {
      needLogin: true
    }
  },{
    path: '/addressList/:isFrom?',
    name: 'addressList',
    component: addressList,
    meta: {
      needLogin: true
    }
  },{
    path: '/addAddress/:addr_id',
    name: 'addAddress',
    component: addAddress,
    meta: {
      needLogin: true
    }
  },{
    path: '/my',
    name: 'my',
    component: my,
    meta: {
      needLogin: true
    }
  },{
    path: '/changePwd',
    name: 'changePwd',
    component: changePwd,
    meta: {
      needLogin: false
    }
  },{
    path: '/register',
    name: 'register',
    component: register,
    meta: {
      needLogin: false
    }
  },
  {
    path: '/wuliu/:id',
    name: 'wuliu',
    component: wuliu,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/wlXq/:id/:type',
    name: 'wlXq',
    component: wlXq,
    meta: {
      needLogin: true
    }
  },
  {
    path: '/phoneLogin',
    name: 'phoneLogin',
    component: phoneLogin,
    meta: {
      needLogin: false
    }
  },
  {
    path: '/pwdLogin',
    name: 'pwdLogin',
    component: pwdLogin,
    meta: {
      needLogin: false
    }
  },{
    path: '/qrcode',
    name: 'qrcode',
    component: qrcode,
    meta: {
      needLogin: true
    }
  },{
    path: '/agreement',
    name: 'agreement',
    component: agreement,
    meta: {
      needLogin: false
    }
  },{
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: forgetPwd,
    meta: {
      needLogin: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
